import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="xl"
        className="bgNav nav-underline"
        style={{ background: "#040b11" }}
      >
        <Container>
          <Navbar.Brand href="/" style={{ height:"90px"}}>
            {" "}
            <img  className="w-100 h-100" src="./assets/image/logo/logo.png"alt="logo"  />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto mainbtn1">
              <Nav.Link className="lenk text-white" href="#Home">
                Home
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#about">
                About
              </Nav.Link>
              {/* <Nav.Link className="lenk text-white" href="Whitepaper.pdf" target="_blank">
                White Paper
              </Nav.Link> */}
              <Nav.Link className="lenk text-white" href="#Benifits">
                Benifits
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#Faq">
                FAQ
              </Nav.Link>
              <Nav.Link className="lenk text-white" href="#contact">
                Contact 
              </Nav.Link>
              <Link
                className="btn-slider  btn  text-white mb-2 mb-lg-0"
                to="/login"
              >
              <span className="tf-button1"><i class="fa fa-sign-in" aria-hidden="true"></i>  Login</span>
              </Link>
              <Link
                className="btn-slider btn text-white ms-md-2"
                to="/Register"
              >
                <span className="tf-button1 style-2"><i class="fa fa-user-plus" aria-hidden="true"></i> Register</span>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
