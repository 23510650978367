import React, { useState, useEffect } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import DOMPurify from "dompurify";

const Level_Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [level, setlevel] = useState(0);
  const [status, setstatus] = useState(2);
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  const Downline_API = async () => {
    try {
      let responce = await API?.post(
        `/LevelDetails`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
          status: status,
          position: 0,
          level: level,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      console.log("LevelDetails", responce?.data?.data);
      responce = responce?.data?.data;
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item?.row,
          Registration_Date: item?.registrationDate,
          User_ID: item?.uid,
          Position: item?.position,
          Wallet: `${item?.wallet?.substring(0, 6)}...${item?.wallet?.substring(
            item?.wallet?.length - 4
          )}`,
          Status: item?.status,
          Level: item?.leveltype,

          Latest: `$ ${item?.Latest}`,
          Gross: `$ ${item?.Gross}`,
          Activation_Date:
            item?.activationDate == null ? "" : item?.activationDate,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Downline_API();
  }, []);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User ID", accessor: "User_ID" },
      { Header: "Wallet", accessor: "Wallet" },
      { Header: "Registration_Date", accessor: "Registration_Date" },
      { Header: "Level", accessor: "Level" },
      { Header: "Status", accessor: "Status" },
      { Header: "Latest Package", accessor: "Latest" },
      { Header: "Gross Package", accessor: "Gross" },
      { Header: "Activation Date", accessor: "Activation_Date" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Level Details</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Level Details</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Level Details
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 mb-3">
            <div className="col-md-3">
              <label className="text-white fs-14">Status</label>
              <select
                className="form-control shadow-none bgmain py-4 fs-14"
                id="status"
                style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                onChange={(e) => setstatus(e.target.value)}
              >
                <option
                  value={2}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  All
                </option>
                <option
                  value={1}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Active
                </option>
                <option
                  value={0}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Inactive
                </option>
              </select>
            </div>
            <div className="col-md-3">
              <label className="text-white fs-14">Level</label>
              <select
                className="form-control shadow-none bgmain py-4 fs-14"
                id="status"
                style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                onChange={(e) => setlevel(e.target.value)}
              >
                <option
                  value={0}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  All
                </option>
                <option
                  value={1}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Leve 1
                </option>
                <option
                  value={2}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Level 2
                </option>
                <option
                  value={3}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Level 3
                </option>
                <option
                  value={4}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Level 4
                </option>
                <option
                  value={5}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Level 5
                </option>
                <option
                  value={6}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Level 6
                </option>
                <option
                  value={7}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Level 7
                </option>
                <option
                  value={8}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Level 8
                </option>
                <option
                  value={9}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Level 9
                </option>
                <option
                  value={10}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Level 10
                </option>
              </select>
            </div>
            <br />
            <br />
            <div className="col-md-auto mt-1">
              <input
                type="button"
                name="to_date"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none w-auto py-3"
                defaultValue="Search"
                onClick={() => Downline_API()}
              />
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 jashja">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Level_Details;
