import React, { useState } from "react";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

const Auto_Sell_Token_Report = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [userid, setUserId] = useState("");

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  console.log("currentPost", currentPost);
  // setcurrentPost(referralApi.slice(indexOfFirstPage, indexOfLastPost));

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "Date", accessor: "Date" },
      { Header: "Amount", accessor: "f_name" },
      { Header: "Token", accessor: "date" },
      { Header: "Sell Rate", accessor: "user_id" },
      { Header: " Type", accessor: "sid" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="d-flex align-items-center justify-content-between py-3">
            <div className="fs-18 text-white fw-bold">
              Auto Sell Token Report
            </div>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">Auto Sell</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="javascript:;">
                        <i className="bx bx-home-alt text-white" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Auto Sell Token Report
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginLeft: 10 }}>
            <div className="col-md-3">
              <label className="text-white fs-12">Enter From Date</label>
              <input
                type="date"
                name="from_date"
                id="from_date"
                className="form-control shadow-none bgmain"
                placeholder="Enter From Date"
              />
            </div>
            <br />
            <br />
            <div className="col-md-3">
              <label className="text-white fs-12">Enter To Date</label>
              <input
                type="date"
                name="to_date"
                id="to_date"
                className="form-control bgmain shadow-none"
              />
            </div>
            <br />
            <br />
            <div className="col-md-auto col-3">
              <input
                type="Find"
                name="to_date"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none"
                defaultValue="Find"
              />
            </div>
            <div className="col-md-auto col-3">
              <input
                type="Reset"
                name="to_date"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none"
                defaultValue="Reset"
              />
            </div>
          </div>
          <br />
          <div className="row mx-0">
            <div className="col-12 jashja newAnimation">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Auto_Sell_Token_Report;
