import React, { useState, useEffect } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import DOMPurify from "dompurify";

const Matrix_Downline = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [search, setSearch] = useState("");

  const [position, setPosition] = useState(0);

  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const DirectLeg_API = async () => {
    try {
      let responce = await API?.get(
        `/directLegBusiness?uid=${userId}`,

        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json", 
          },
        }
      );
      console.log("activationHistory", responce);
      responce = responce?.data?.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item?.row,
          Registration_Date: item?.registrationDate,
          User_ID: item?.uid,
          Position: item?.position,
          Wallet: `${item?.wallet?.substring(0, 6)}...${item?.wallet?.substring(
            item?.wallet?.length - 4
          )}`,
          selfbv:`$ ${item?.selfbv}`,
          teambv:`$ ${item?.teambv}`,
          gbv:`$ ${item?.gbv}`
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    DirectLeg_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User ID", accessor: "User_ID" },
      { Header: "Wallet", accessor: "Wallet" },
      { Header: "Self Business", accessor: "selfbv" },
      { Header: "Team Business", accessor: "teambv" },
      { Header: "Gross Business", accessor: "gbv" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  Direct Business
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">
                    Direct Business
                  </div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Direct Business
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mx-0 mb-3">
            <div className="col-md-3">
              <label className="text-white fs-14">User Id</label>
              <input
                type="text"
                name="from_date"
                id="from_date"
                className="form-control text-white shadow-none text-white bgmain"
                placeholder="User ID"
                defaultValue=""
              />
            </div>
            <br />
            <br />
            <div className="col-md-3">
              <label className="text-white fs-14">Position</label>
              <select
                className="form-control shadow-none bgmain"
                id="status"
                style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
              >
                <option value="" style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}>
                  Select Position
                </option>
                <option value={0} style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}>
                  All
                </option>
                <option value={1} style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}>
                  Left
                </option>
                <option value={2} style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}>
                  Right
                </option>
              </select>
            </div>
            <br />
            <br />
            <div className="col-md-auto mt-1">
              <input
                type="submit"
                name="to_date"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none w-auto"
                defaultValue="Search"
              />
            </div>
          </div> */}
          <div className="row mx-0">
            <div className="col-12 jashja newAnimation">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Matrix_Downline;
