import React, { useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

const Sell_Rate_Chart = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [userid, setUserId] = useState("");

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  console.log("currentPost", currentPost);
  // setcurrentPost(referralApi.slice(indexOfFirstPage, indexOfLastPost));

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "Buy Rate", accessor: "sid" },
      { Header: "Sell Rate", accessor: "user_id" },
      { Header: "USD", accessor: "f_name" },
      { Header: "Token.No", accessor: "date" },
      { Header: "Remark", accessor: "level" },
      { Header: "Txn", accessor: "Txn" },
      { Header: "Date", accessor: "Date" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper px-0">
        <div className="page-content adjustmentsection">
          <div className="row">
            <div className="col-12">
              <div className="heading text-white fw-bold fs-18 mb-3">
                ADJUSTMENT
              </div>
            </div>
            <div className="col-12 jashja newAnimation">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Sell_Rate_Chart;
